export const FirebaseConfig = {
	"projectId": "easecrop-370707",
	"appId": "1:699037939435:web:2a9d4358733824f4af30ae",
	"databaseURL": "https://easecrop-370707-default-rtdb.firebaseio.com",
	"storageBucket": "easecrop-370707.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCShoNwD1r2tmJ2noBoV8fbhza5iry1Xf8",
	"authDomain": "easecrop-370707.firebaseapp.com",
	"messagingSenderId": "699037939435",
	"measurementId": "G-N3TP9NMTKD"
};